const axios = window.axios
const url = '/api/v2/categories/'
const urlRoom = '/api/v2/categories/rooms/'

export const CategoriesApiUrls = {
  // categories категории в настройках зона отдыха
  async getCategories(params) {
    return (await axios.get(url, {
      params: params
    })).data.results;
  },
  async getCategoriesMapping() {
    return (await axios.get(`${url}mapping/`)).data;
  },
  async createCategory(body) {
    return (await axios.post(url, body)).data;
  },
  async getCategory(id) {
    return (await axios.get(`${url}${id}/`)).data;
  },
  async setCategory(id, body) {
    return (await axios.put(`${url}${id}/`, body)).data;
  },
  async deleteCategory(id) {
    return (await axios.delete(`${url}${id}/`)).data;
  },


  // добавить картинки в категорию
  async addMainImage(formdata, id) {
    return (await axios.patch(`${url}images/main/${id}/`, formdata)).data;
  },
  async addImage(formdata) {
    return (await axios.post(`${url}images/`, formdata)).data;
  },
  async deleteImage(id) {
    return (await axios.delete(`${url}images/${id}/`)).data;
  },

  // rooms номера в настройках зона отдыха
  async getRooms(params) {
    return (await axios.get(`${urlRoom}`, {
      params: params
    })).data;
  },
  async getSelectedRooms(id) {
    return (await axios.get(`${url}category-rooms/${id}/`)).data;
  },
  async createRoom(body) {
    return (await axios.post(`${urlRoom}`, body)).data;
  },
  async getRoom(id) {
    return (await axios.get(`${urlRoom}${id}/`)).data;
  },
  async setRoom(id, body) {
    return (await axios.put(`${urlRoom}${id}/`, body)).data;
  },
  async deleteRoom(id) {
    return (await axios.delete(`${urlRoom}${id}/`)).data;
  },

  // закрыть на ремонт
  async repairRoom(body) {
    return (await axios.post(`${url}room-repair/`, body)).data;
  },
  // удалить ремонт
  async deleteRepairRoom(id) {
    return (await axios.delete(`${url}room-repair/${id}/`)).data;
  },
  // добавить тэги в категорию
  async createTag(body) {
    return (await axios.post(`${url}tags/`, body)).data;
  },
  async setTag(body, id) {
    return (await axios.patch(`${url}tags/${id}/`, body)).data;
  },
  async deleteTag(id) {
    return (await axios.delete(`${url}tags/${id}/`)).data;
  },
  async getBcomRooms(channel_id) {
    return (await axios.get(`${url}get-bcom-rooms/${channel_id}/`)).data;
  },
  async mapBcomRooms(channel_id, rmap) {
    return (axios.post(`${url}map-bcom-rooms/${channel_id}/`, rmap)).data
  }
};