<template>
  <!-- попап с квизом опреденной брони -->
  <v-container>
    <div style="width: 100%; display: flex; justify-content: end">
      <v-icon @click="closeAnddelete" v-if="isCloseVisible" large
        >mdi-close</v-icon
      >
    </div>
    <v-stepper v-model="$store.state.el">
      <v-stepper-header class="overflow-x-auto flex-nowrap">
        <v-stepper-step
          style="cursor: pointer"
          step="1"
          :complete="false"
          @click="$store.commit('TO_TAB', 1)"
        >
          Информация о брони
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          style="cursor: pointer"
          step="2"
          :complete="false"
          @click="$store.commit('TO_TAB', 2)"
        >
          Данные клиента
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          style="cursor: pointer"
          step="3"
          :complete="false"
          @click="$store.commit('TO_TAB', 3)"
        >
          Питание
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          style="cursor: pointer"
          step="4"
          :complete="false"
          @click="$store.commit('TO_TAB', 4)"
        >
          Услуги
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          style="cursor: pointer"
          step="5"
          :complete="false"
          @click="$store.commit('TO_TAB', 5)"
        >
          Стоимость
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          style="cursor: pointer"
          step="6"
          :complete="false"
          @click="$store.commit('TO_TAB', 6)"
        >
          Задачи
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          style="cursor: pointer"
          step="7"
          :complete="false"
          @click="$store.commit('TO_TAB', 7)"
        >
          Подтверждение
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-3 pa-md-5">
          <step1
            @openDelete="openDeleteForm"
            @openRoomReason="openRoomReason"
          />
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-3 pa-md-5">
          <step2
            ref="clients"
            :required_fields="required_fields"
            :categories="all_categories"
          />
        </v-stepper-content>
        <v-stepper-content step="3" class="pa-3 pa-md-5">
          <step-food />
        </v-stepper-content>
        <v-stepper-content step="4" class="pa-3 pa-md-5">
          <step3 :services="services" />
        </v-stepper-content>
        <v-stepper-content step="5" class="pa-3 pa-md-5">
          <step4 @closeBtn="closeBtn" />
        </v-stepper-content>
        <v-stepper-content step="6" class="pa-3 pa-md-5">
          <step5 ref="tasks" />
        </v-stepper-content>
        <v-stepper-content step="7" class="pa-3 pa-md-5">
          <total />
        </v-stepper-content>
        <v-divider />
      </v-stepper-items>
      <div class="px-3 mb-3 px-md-6">
        <v-row class="mt-2">
          <v-col cols="12" sm="2" md="2">
            <v-btn
              @click="create"
              :loading="saveBtnLoading"
              :disabled="disabledBtn"
              color="warning"
              style="width: 100%"
              v-if="btnLabel"
            >
              {{ btnLabel }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-btn
              @click="preupdateOrder"
              :loading="isUpdating"
              :disabled="disabledBtn"
              color="warning"
              style="width: 100%"
            >
              Сохранить {{ getSteady().is_group ? "для всех" : "" }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="3">
            <v-btn class="gray____button" @click="open_delete_reason = true" style="width: 100%">
              {{ $store.state.status == "new" ? "Удалить" : "Отменить бронь" }}
              {{ getSteady().is_group ? "для всех" : "" }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-stepper>
    <!-- попап где нужно написать причину отмены -->
    <v-dialog v-model="open_delete_reason" width="500">
      <v-card class="pa-4">
        <v-row>
          <v-col cols="12" sm="6" md="12">
            <h2 class="popup_delete_heading">Не забудьте сделать возврат</h2>
            <v-text-field
              v-model="delete_reason"
              label="Укажите причину отмены"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-btn
              color="warning"
              class="mr-6"
              @click="sendReviewForDelete"
              :disabled="disabledBtn"
            >
              Отменить бронь
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-btn
              color="primary"
              outlined
              class="mr-6"
              @click="open_delete_reason = false"
              style="position: absolute; bottom: 32px; right: 0"
            >
              Отмена
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- попап где нужно написать причину переселения номера -->
    <v-dialog v-model="room_reason_modal" width="500">
      <v-card class="pa-4">
        <v-row>
          <v-col cols="12" sm="6" md="12">
            <h2 class="popup_delete_heading">
              Напишите причину переселения номера
            </h2>
            <v-text-field
              v-model="room_reason"
              label="Укажите причину переселения"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <div class="text-center">
            <v-btn color="primary" outlined class="mr-6" @click="updateOrder">
              Сохранить
            </v-btn>
          </div>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Total from "../components/Total";
import Step1 from "../components/booking/Step1";
import Step2 from "../components/booking/Step2";
import Step3 from "../components/booking/Step3";
import Step4 from "../components/booking/Step4";
import Step5 from "../components/booking/Step5";
import moment from "moment";
import toast from "../../../plugins/toast";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js";
import { ServiceApiUrls } from "@/services/accommodationRequests/service.api.js";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";
import { CompaniesApiUrls } from "@/services/accommodationRequests/companies.api.js";
import StepFood from "@/views/main/components/booking/StepFood.vue";

export default {
  components: {
    StepFood,
    Total,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  props: {
    date: String,
    clear: Function,
  },
  data() {
    return {
      isUpdating: false,
      open_room_reason: false,
      room_reason: null,
      room_reason_modal: false,
      group_one_item: null, // удалить только одну бронь в групповой брони
      arr_indexes: [], // index: categories, sub_index: child_list
      required_fields: [],
      disabledBtn: false,
      isCloseVisible: true,
      delete_reason: "",
      open_delete_reason: false,
      saveBtnLoading: false,
      all_categories: [],
      e1: 1,
      services: [],
      rooms: [],
    };
  },
  computed: {
    ...mapGetters(["getSteady", "getPossibleCategories"]),
    btnLabel() {
      let label;
      let obj = {
        reserved: "Бронирование",
        booked: "Заселить",
        check_in: "Выселить",
      };
      label = obj[this.getSteady().booking_status];
      return label;
    },
  },
  watch: {
    "$store.state.el": async function () {
      if (this.$store.state.el == 4) {
        let response = await ServiceApiUrls.getServices();
        this.initializeServices(response.results);
      }
    },
  },
  async created() {
    this.all_categories = this.getPossibleCategories();
    this.rooms = (await CategoriesApiUrls.getRooms()).results;
    this.initializeServices((await ServiceApiUrls.getServices()).results);
    this.loadRequiredFields();
  },
  methods: {
    ...mapMutations({
      reserve: "SET_RESERVE",
      setPopulate: "SET_POPULATE",
      setStatus: "SET_STATUS",
      setCheckinNull: "setCheckinNull"
    }),
    clearCheckInOut() {
      this.getSteady().check_in = null;
      this.getSteady().check_out = null;
      console.log(this.getSteady().check_in,this.getSteady().check_out)
    },
    // загрузить обязательные поля компании
    async loadRequiredFields() {
      let response = await CompaniesApiUrls.getInfo();
      for (const [key, value] of Object.entries(response.required_fields)) {
        this.required_fields.push(value.name);
      }
      if (this.required_fields.length == 0) {
        if (
          this.getSteady().booking_status == "reserved" ||
          this.getSteady().booking_status == "booked"
        ) {
          this.required_fields = ["first_name", "phone_number"];
        }
      }
    },
    // нужно для иконки загрузки
    async preupdateOrder () {
      this.isUpdating = true;
      try {
        await this.updateOrder();
      } catch (e) {
        this.isUpdating = false;
      }
      this.isUpdating = false;
    },
    // сохранить бронь
    async updateOrder() {
      if (
        this.open_room_reason &&
        this.room_reason_modal &&
        !this.room_reason
      ) {
        toast.error("Напишите причину переселения");
        return;
      }
      if (
        this.open_room_reason &&
        !this.room_reason_modal &&
        !this.room_reason
      ) {
        this.room_reason_modal = true;
        return;
      }
      let str = this.getSteady().is_group
        ? "group_calculation"
        : "calculated_by";
      for (let item of this.getSteady().clients_list) {
        for (const [key, value] of Object.entries(item)) {
          if (
            (this.required_fields.includes(key) && !value) ||
            (this.required_fields.includes(key) && value.length == 0)
          ) {
            if (
              key == "first_name" &&
              item.full_name &&
              item.full_name.length > 0
            )
              continue;
            this.$store.commit("TO_TAB", 2);
            this.$refs.clients.validate();
            return;
          }
        }
      }
      if (this.getSteady().room === null) {
        toast.error("Пожалуйста выберите номер!");
        return;
      }

      // проверка если во всех питаниях выбран номер
      for (const foodElement of this.getSteady().food_list) {
        if (typeof foodElement.booking !== "number") {
          toast.error("Сначала сохраните бронь без питания")
          return;
        }
      }

      if (this.getSteady().task_list) {
        this.getSteady().task_list.forEach((element) => {
          if (typeof element.todo_date == "number")
            element.due_date = element.todo_date;
          else {
            let year = element.todo_date.split("-")[0],
              month = Number(element.todo_date.split("-")[1]) - 1,
              day = element.todo_date.split("-")[2],
              hours = element.todo_time.split(":")[0],
              minutes = element.todo_time.split(":")[1];
            element.due_date =
              new Date(year, month, day, hours, minutes).getTime() / 1000;
          }
          console.log(element.due_date, element.todo_date, element.todo_time);
          if (typeof element.responsible == "object") {
            element.responsible = element.responsible.id;
          }
        });
        let bool = false;
        this.getSteady().task_list.forEach((e) => {
          if (!e.responsible || !e.description || !e.title || !e.due_date) {
            toast.error("Пожалуйста заполните задачу!");
            this.$store.commit("TO_TAB", 5);
            bool = true;
          }
        });
        if (bool) return;
      }
      if (
        this.open_room_reason &&
        !this.room_reason_modal &&
        !this.room_reason
      ) {
        this.room_reason_modal = true;
        this.room_reason = null;
        return;
      }
      if (this.room_reason) {
        this.getSteady().room_reason = this.room_reason;
        this.room_reason_modal = false;
      }
      if (this.getSteady().has_expire) {
        let expire_on_date = this.getSteady().expire_on_date.split(".");
        if (expire_on_date.length == 1)
          expire_on_date = this.getSteady().expire_on_date.split("-");
        expire_on_date = `${expire_on_date[1]}.${expire_on_date[0]}.${expire_on_date[2]}`;
        console.log(expire_on_date, this.getSteady().expire_on_time);
        let year = expire_on_date.split(".")[2],
          month = Number(expire_on_date.split(".")[0]) - 1,
          day = expire_on_date.split(".")[1],
          hours = this.getSteady().expire_on_time.split(":")[0],
          minutes = this.getSteady().expire_on_time.split(":")[1];
        this.getSteady().expire_on =
          new Date(year, month, day, hours, minutes).getTime() / 1000;
      }
      if (this.getSteady()[str].services) {
        this.getSteady()[str].services.forEach((e) => {
          e.service = e.service || e.id;
        });
      }
      let child_list = [];
      let check_in, check_out;
      if (this.getSteady().is_group) {
        this.getSteady().categories.forEach((e) => {
          e.child_list.forEach((j) => {
            child_list.push(j);
            if (typeof j.check_in == "number") {
              j.check_in =
                moment(new Date(+j.check_in * 1000)).format("YYYY-MM-DD") +
                " " +
                j.hour_check_in;
            }
            if (typeof j.check_out == "number") {
              j.check_out =
                moment(new Date(+j.check_out * 1000)).format("YYYY-MM-DD") +
                " " +
                j.hour_check_out;
            }
          });
        });
      }
      // check_in изменить формат
      else {
        check_in = moment(+this.getSteady().check_in * 1000).format(
          "YYYY-MM-DD HH:mm"
        );
        check_out = moment(+this.getSteady().check_out * 1000).format(
          "YYYY-MM-DD HH:mm"
        );
      }
      this.$store.commit("SET_CHECK_DELETE", false);

      this.saveBtnLoading = false;
      this.disabledBtn = false;
      this.isCloseVisible = true;
      try {
        if (this.getSteady()[str].services) {
          this.getSteady()[str].services = this.getSteady()[
            str
          ].services.filter((element) => {
            return element.quantity != 0;
          });
        }
        let resp;
        let task_list = [];
        this.getSteady().task_list.forEach((e) => {
          task_list.push({
            ...e,
            responsible: e.responsible.id ? e.responsible.id : e.responsible,
          });
        });
        this.getSteady().food_list = this.getSteady().food_list.map((i) => {
          const obj = {...i, tariff: i.tariff.id};
          delete obj.id;
          return obj;
        });
        this.getSteady()[str].food_list = this.getSteady().food_list.map((i) => {
          const obj = {...i};
          delete obj.id;
          return obj;
        });
        let food_list = this.getSteady().food_list.map(rest => {
            if (isNaN(rest.id)) {
                delete rest.id;
            }
            return rest;
        });
        if (this.getSteady().is_group) {
          resp = await BookingApiUrls.updateAllBookings(
            this.getSteady().group_parent,
            {
              ...this.getSteady(),
              service_list: this.getSteady().group_calculation.services,
              child_list: child_list,
              task_list: task_list,
            }
          );
        } else {

          console.log(this.getSteady());
          resp = await BookingApiUrls.setBooking(this.getSteady().id, {
            ...this.getSteady(),
            service_list: this.getSteady()[str].services,
            check_in: check_in,
            check_out: check_out,
            task_list: task_list,
            food_list: food_list
          });
        }
        console.log(resp.data);
        this.$store.commit("SET_STEADY", resp.data);
        console.log(this.getSteady());
        this.$store.commit("SET_UPDATED_STEADY", 0);
        this.setStatus("");
        toast.success("Изменилось");
      } finally {
        this.saveBtnLoading = false;
        this.disabledBtn = false;
        this.isCloseVisible = true;
      }
    },
    // скрыть кнопку close, чтобы сначала сохранили бронь
    closeBtn() {
      this.isCloseVisible = false;
    },
    // закрыть бронь
    closeAnddelete() {
      if (this.$store.state.check_delete) {
        this.removeBooking();
      }
      // this.clearCheckInOut()
      // this.setCheckinNull(null)
      this.$store.commit("SET_CHECK_DELETE", false);
      this.setStatus("");
      this.clear();
    },
    // открыть попап где нужно написать причину отмены
    openDeleteForm(value) {
      this.open_delete_reason = true;
      this.group_one_item = value.id;
      this.arr_indexes = [value.index, value.sub_index];
    },
    // отменить бронь
    async sendReviewForDelete() {
      let id;
      if (this.group_one_item && this.getSteady().is_group) {
        id = this.group_one_item;
      } else if (!this.group_one_item && this.getSteady().is_group) {
        id = this.getSteady().group_parent;
      } else {
        id = this.getSteady().id;
      }
      this.disabledBtn = true;
      if (this.$store.state.status == "new") {
        try {
          if (this.getSteady().is_group && !this.group_one_item) {
            await BookingApiUrls.deleteGroupPermanentlyBooking(id);
          } else {
            await BookingApiUrls.deletePermanentlyBooking(id);
          }
          this.group_one_item = null;
          this.disabledBtn = false;
          this.setStatus("");
          this.clear();
        } catch (e) {
          this.disabledBtn = false;
          this.arr_indexes = [];
          this.group_one_item = null;
        }
      } else {
        if (this.getSteady().is_group && !this.group_one_item) {
          await BookingApiUrls.deleteGroupBooking(id, {
            cancel_reason: this.delete_reason,
          })
            .then(() => {
              this.closeAnddelete();
            })
            .catch((err) => {
              this.arr_indexes = [];
              this.group_one_item = null;
              this.disabledBtn = false;
            });
        } else {
          await BookingApiUrls.deleteBooking(id, {
            cancel_reason: this.delete_reason,
          })
            .then(() => {
              this.closeAnddelete()
              this.getSteady().categories[
                this.arr_indexes[0]
              ].child_list.splice(this.arr_indexes[1], 1);
              this.getSteady().categories.forEach((e, index) => {
                e.rooms.forEach((j, sub_index) => {
                  let here = false;
                  e.child_list.forEach((k) => {
                    if (k.room == j) here = true;
                  });
                  if (!here) e.rooms.splice(sub_index, 1);
                });
              });
              this.group_one_item = null;
              this.room_reason = null;
              this.delete_reason = '';
              this.open_delete_reason = false;
              this.disabledBtn = false;
              window.location.reload();
            })
            .catch((err) => {
              this.arr_indexes = [];
              this.open_delete_reason = false;
              this.disabledBtn = false;
            });
        }
      }
    },
    // удалить бронь
    async removeBooking() {
      this.disabledBtn = true;
      if (this.$store.state.status == "new") {
        try {
          await BookingApiUrls.deletePermanentlyBooking(this.getSteady().id);
          this.disabledBtn = false;
        } catch (e) {
          this.disabledBtn = false;
        }
      } else {
        try {
          await BookingApiUrls.deleteBooking(this.getSteady().id, {
            cancel_reason: this.delete_reason,
          });
          this.disabledBtn = false;
          this.$store.commit("CLEAR_CALENDAR"); // почистить шахматку
          this.clear();
        } catch (e) {
          this.disabledBtn = false;
        }
      }
    },
    // добавить сервисы
    initializeServices(dataSet) {
      this.services = [];
      dataSet.map((el) => {
        let quantity = 0;
        let rooms = [];
        let str = this.getSteady().is_group
          ? "group_calculation"
          : "calculated_by";
        if (this.getSteady()[str]?.services?.length > 0) {
          let tmp = this.getSteady()[str]?.services.filter(
            (elem) => elem.service === el.id || elem.id === el.id
          );
          if (tmp.length > 0) {
            quantity = tmp[0].quantity;
            rooms = tmp[0].rooms;
          }
        }
        let total = 0;
        if (el.service_type === "day") {
          let days;
          if (this.getSteady().is_group) {
            rooms.forEach((i) => {
              this.getSteady().categories.forEach((j) => {
                j.child_list.forEach((k) => {
                  if (k.id == i) {
                    days = Math.ceil(
                      (new Date(+k.check_out) - new Date(+k.check_in)) /
                        (60 * 60 * 24)
                    );
                    total += days * el.price * quantity;
                  }
                });
              });
            });
          } else {
            days = Math.ceil(
              (new Date(+this.getSteady().check_out) -
                new Date(+this.getSteady().check_in)) /
                (60 * 60 * 24)
            );
            total = el.price * quantity * days;
          }
        } else {
          total = this.getSteady().is_group
            ? el.price * quantity * rooms.length
            : el.price * quantity;
        }
        const obj = {
          id: el.id,
          service: el.name,
          price: el.price,
          type: el.service_type,
          rooms: rooms || el.rooms,
          quantity,
          total,
        };
        this.services.push(obj);
      });
      console.log(this.services[0].quantity);
    },
    // изменить статус брони
    async create() {
      for (let item of this.getSteady().clients_list) {
        for (const [key, value] of Object.entries(item)) {
          if (
            (this.required_fields.includes(key) && !value) ||
            (this.required_fields.includes(key) && value.length == 0)
          ) {
            if (
              key == "first_name" &&
              item.full_name &&
              item.full_name.length > 0
            )
              continue;
            this.$store.commit("TO_TAB", 2);
            this.$refs.clients.validate();
            return;
          }
        }
      }

      if (this.getSteady().room === null) {
        toast.error("Пожалуйста выберите номер");
        return;
      }
      if (this.btnLabel == "Бронирование") {
        if (
          this.getSteady().payment_list &&
          this.getSteady().payment_list.length == 0
        ) {
          toast.error("Пожалуйста внесите оплату");
          return;
        }
      }
      if (this.btnLabel == "Заселить") {
        console.log(this.getSteady());
      }

      this.saveBtnLoading = true;
      this.disabledBtn = true;
      try {
        let body = {};
        let id = null;
        if (this.getSteady().is_group) {
          id = this.getSteady().group_parent;
          body.child_list = [];
          this.getSteady().categories.forEach((e) => {
            e.child_list.forEach((j) => {
              body.child_list.push(j);
            });
          });
          body = { ...this.getSteady(), ...body };

          if (body.services && body.services.length > 0) {
            body.service_list = body.services;
          } else if (
            body.group_calculation &&
            body.group_calculation.services &&
            body.group_calculation.services.length > 0
          ) {
            body.service_list = body.group_calculation.services;
          } else {
            body.service_list = [];
          }
        } else {
          id = this.getSteady().id;
          body = { ...this.getSteady() };
          if (body.services && body.services.length > 0) {
            body.service_list = body.services;
          } else if (
            body.calculated_by &&
            body.calculated_by.services &&
            body.calculated_by.services.length > 0
          ) {
            body.service_list = body.calculated_by.services;
          } else {
            body.service_list = [];
          }
        }
        body.task_list = [];
        this.getSteady().task_list.forEach((e) => {
          body.task_list.push({
            ...e,
            responsible: e.responsible.id ? e.responsible.id : e.responsible,
          });
        });

        // питание
        body.food_list = this.getSteady().food_list.map((i) => {
          const obj = {...i, tariff: i.tariff.id};
          delete obj.id;
          return obj;
        });
        console.log("SDFSDFFSD ", body.food_list);

        let resp;
        if (this.getSteady().is_group) {
          resp = await BookingApiUrls.updateAllBookingsStatus(id, body);
        } else {
          resp = await BookingApiUrls.changeRealBooking(id, body);
        }
        toast.success("Изменен статус!");
        this.$store.commit("SET_STEADY", resp.data);
      } finally {
        this.disabledBtn = false;
        this.saveBtnLoading = false;
      }
    },
    // открыть где нужно написать причину переселения номера
    openRoomReason() {
      this.room_reason = null;
      this.open_room_reason = true;
    },
  },
};
</script>
<style>
.hr-divider {
  border-color: #ff9800 !important;
}
.popup_delete_heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  color: #021011;
}
</style>
